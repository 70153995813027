import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#B99225'
let black = 'rgb(38,38,38)'

let id = 'shinta-galih'
let inisial_co = 'Galih'
let inisial_ce = 'Shinta'
let lengkap_co = 'Galih Rizky Prabowo'
let lengkap_ce = 'Shinta Gustriani Swasti'
let bapak_co = 'Bpk. Zainudin'
let ibu_co = 'Ibu Etik Suwartinah'
let bapak_ce = "Bpk. Moch Herry Suhaeri "
let ibu_ce = "Ibu Tenny Yuliantini Affandi"
let ig_co = "galihrizkyprabowo"
let ig_ce = "shintagustriani"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "02/21/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/eJkZsXiKBGcuF1kWA"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MW02YnEwNHRxMWVvNTBtYTVldWlhamtlcjkgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3960.5345312211916!2d107.6381569!3d-6.9460946!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e8698efedb29%3A0x49a7a9741606395b!2sPaberik%20Badjoe%20Factory%20Outlet!5e0!3m2!1sid!2sid!4v1610629337540!5m2!1sid!2sid"



export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
        let s = this.useQuery().get('s');
        if (s == 'xcv') {
            this.setState({ sesi: 1 })
        }
        if (s == 'cvb') {
            this.setState({ sesi: 2 })
        }

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${this.pesan.current.value}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let slider = []
        let slide = [
            "16RP_IMG_8341.jpg",
            "20RP_IMG_8880.jpg",
            "4R_IMG_8426.jpg",
            "4R_IMG_8530.jpg",
            "4R_IMG_8796.jpg",
            "8RP_IMG_8780.jpg",
            "8RP_IMG_9108.jpg",
        ]
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.6&w=1440&h=720'))
        })
        let query = this.useQuery().get('u');

        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundColor: `#293D58`,
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={gambar(openlogo)} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>

                                        <p className="fs16 text-center  px-3" style={{color:'white'}}>
                                        “Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan
                                                    untukmu isteri-isteri dari jenismu sendiri, supaya kamu
                                                    cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya
                                                    diantaramu rasa kasih dan sayang. Sesungguhnya pada yang
                                                    demikian itu benar-benar terdapat tanda-tanda bagi kaum
                                              yang berfikir.” <br />
                                                    <i>(QS. Ar-Rum: 21)
                                                  </i>
                                        </p>
                                    </Item>
                                    {/* <div className="embed-responsive embed-responsive-4by3 mb-3 p-3">
                                        <iframe className="embed-responsive-item"
                                            src="https://www.youtube.com/embed/Fx-v-YDdfb0"></iframe>
                                    </div> */}
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={pw("asset", "burung-gold.svg")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>

                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            The Intimate Wedding of <br /> {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '32px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={pw("asset", "logoig-gold.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '32px',
                                                        fontFamily: "'Marck Script', cursive", color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putra dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={pw("asset", "logoig-gold.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: 'white' }} >
                                    <Item>
                                        <p className="fs16">
                                            Yang Insyaa Allah akan dilaksanakan pada:
                                        </p>
                                    </Item>
                                    {/* <Item>
                                        <p className="fs20">
                                            <b>
                                                SABTU <span className="fs36">12</span> DES 2020
                      </b>
                                        </p>
                                    </Item> */}
                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: 'white' }}>
                                            <b>Akad Nikah </b><br />
                                            <span className=" fs16">
                                                <b>
                                                    21 Februari 2021
                                                </b><br />
                                            09.00 WIB
                      </span>
                                            {/* <br />
                      <span className="cblack fs12">
                        (Hanya dihadiri oleh keluarga)
                      </span> */}

                                        </p>
                                        <p className="px-3 d-none d-sm-block" style={{ color: cmain, fontSize: '72px' }}>
                                            \
                                        </p>
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: 'white' }}>
                                            <b>Resepsi</b><br />
                                            <span className=" fs16">
                                                <b>21 Februari 2021
                                                    </b><br />
                                                    10.00 - 12.00 WIB
                                               </span>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs16 pt-3">
                                            <b>Pabrik Badjoe</b><br/>
                                            Jl. Soekarno-Hatta No.573, Kec. Batununggal, Kota Bandung, Jawa Barat
                                        </p>
                                    </Item>
                                    <Item>
                                        <p style={{ fontSize: '16px', fontStyle: 'italic' }}>Undangan Hanya untuk 2 orang</p>
                                    </Item>
                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />

                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={pw("asset", "covid-gold.svg")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>
                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                            True love is when both people think they are the lucky one

</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>

                                <Foot ig={pw("asset", "logoig-gold.svg")} />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

